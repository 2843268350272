@import './../index.scss';

.button{
    @include borderRadius(5px);
    min-width: 42px;
    min-height: 42px;
    text-align: center;
    // border: solid 1px grey;
}
.button.hover{
    background-color: rgba(255,255,255,0.3);
}

.header{
    grid-template-columns: minmax(42px, 72px) 1fr auto;
    // background-color: $colorBlue;
    grid-row: 1/2;
    grid-column: 1/1;

    .logo img{
        height: 48px;
    }

    .menu{
        @include borderRadius(3px);
        position: absolute;
        z-index: 20;
        min-width: 190px;
        // top:65px;
        // right: 5px;
        margin-top: 63px;
        right: 3px;
        padding: 0px;
        list-style-type: none;
        background-color: rgba(255,255,255,1);
        box-shadow: 0px 4px 14px .2px #0003;


        li{
            padding: 10px 40px 10px 20px;
            border-bottom: solid 1px #ddd;
            margin: 3px;
        }

        li:last-child{
            border: none;
        }

        li a{
            color: inherit !important;
        }
    }
}
// disabled as it was affecting site footer
// .footer{
//     @include borderRadius(0px);
//     display: grid;
//     align-items: center;
//     text-align: center;
//     font-size: 12px;
//     padding: 5px 0px;
//     grid-row: 5/6;
//     grid-column: 1/1;
//     background-color: #4a8ad3;
//     color: #ededed;
// }

.virtualKeyboard,
.virtualKeyboard2,
.virtualKeyboard3,
.virtualKeyboardSubmit,
.virtualKeyboardSubmitAndClear {
    // background-color: #dcddde28;
    // background-color: #fff;
    background-color: #eee3;
    margin: 10px auto;
    // aspect-ratio: 1.5;
    width: -webkit-fill-available;
    height: auto;
    min-height: 12em;
    border-radius: 5px;
    display: grid;
    place-content: center;
    gap: 5px;
    filter: drop-shadow(-1px 3px 2px #0003);
    color: #333;
}

.virtualKeyboard{
    grid-template-areas:
    'num0 num1 num2 num3'
    'num4 num5 num6 num7'
    'blank num8 num9 blank2';
    padding: 10px;
    margin-bottom: 0px;
    font-size: 1.3em;
    font-weight: bold;
    height: auto;
    min-height: fit-content;
    div{
        @include borderRadius(3px);
        background-color: $colorWhite;
        display: grid;
        justify-content: center;
        align-content: center;
        outline: solid .75px grey;
        min-height: 48px;
        min-width: 48px;
        aspect-ratio: 1;
    }

    // .undo{
    //     background-color: rgb(114, 188, 223);
    //     grid-column: 3/6;
    // }

    .item1{grid-area: num1;}
    .item2{grid-area: num2;}
    .item3{grid-area: num3;}
    .item4{grid-area: num4;}
    .item5{grid-area: num5;}
    .item6{grid-area: num6;}
    .item7{grid-area: num7;}
    .item8{grid-area: num8;}
    .item9{grid-area: num9;}
    .item0{grid-area: num0;}
    .blank{grid-area: blank; background-color: unset; border: unset;}
    .blank2{grid-area: blank2; background-color: unset; border: unset;}
}
.clearAnswer{@include borderRadius(5px); background-color: $colorRed; padding: 15px; text-align: center; box-shadow: 0 4px 8px #0002; width: 45px; height: 45px; aspect-ratio: 1; border: none}
.submit{@include borderRadius(5px); background-color: $colorGreen; padding: 15px; text-align: center; box-shadow: 0 4px 8px #0002; width: 45px; height: 45px; aspect-ratio: 1; border: none}
.clearAnswer.disabled,.submit.disabled{background-color: #ccc; color: #999; box-shadow: 0 4px 8px #0000}

.virtualKeyboard2{
    grid-template-columns: repeat(6,1fr);
    grid-template-areas:
    'num1 num2 num3 num4 num5 submit'
    'num6 num7 num8 num9 num0 submit'
    'clear clear clear clear clear submit';
    div{
        min-height: 44px;
        min-width: unset;
    }
}

.virtualKeyboard2, .virtualKeyboard3{
    .numericButtons{
        opacity: 0;
        display: none;
    }
}

.virtualKeyboard3{
    grid-template: 1fr / 1fr 1fr;
    padding: 10px;
    margin-bottom: 0px;
    font-size: 27px;
    font-variant: small-caps;
    min-height: fit-content;
    align-items: center;

    div{
        @include borderRadius(6px);
        background-color: $colorWhite;
        display: grid;
        outline: solid .75px grey;
        // height: 44px;
        height: 4em;
        justify-content: center;
        align-content: center;
    }

    .item1{grid-area: 1/1/2/2; color: green; border: solid 3px #00000000;}
    .item5{grid-area: 1/2/2/3; color: red; border: solid 3px #00000000;}
    // .item2{grid-area: num2;}
    // .item3{grid-area: num3;}
    // .item4{grid-area: num4;}
    // .item6{grid-area: num6;}
    // .item7{grid-area: num7;}
    // .item8{grid-area: num8;}
    // .item9{grid-area: num9;}
    // .item0{grid-area: num0;}

    .highlight{
        border-color: #FF0000;
    }
}

.virtualKeyboardSubmit, .virtualKeyboardSubmitAndClear{
    display: grid;
    gap: 5px;
    // grid-template-areas:
    // 'num1 num1 num3 num5 num5 submit'
    // 'num1 num1 num8 num5 num5 submit'
    // 'clear clear clear clear clear submit';
    grid-template-areas: 'submit';
    padding: 10px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;

    div{
        @include borderRadius(3px);
        background-color: #fdffe3;
        display: grid;
        outline: solid .75px grey;
        // height: 44px;
        min-height: 44px;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    // .item1{grid-area: num1; font-size: 30px; color: green;}
    // .item5{grid-area: num5; font-size: 30px; color: red;}
    // .item2{grid-area: num2;}
    // .item3{grid-area: num3;}
    // .item4{grid-area: num4;}
    // .item6{grid-area: num6;}
    // .item7{grid-area: num7;}
    // .item8{grid-area: num8;}
    // .item9{grid-area: num9;}
    // .item0{grid-area: num0;}
    .clearAnswer{background-color: $colorRed; padding: 2ch;}
    .submit{background-color: $colorGreen;}
    .clearAnswer.disabled,.submit.disabled{background-color: #ccc; color: #999; padding: 2ch;}
}

.clearAnswer::after{
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='45' height='45' viewBox='0 0 45 45'%3E%3Cdefs%3E%3CclipPath id='clip-undo'%3E%3Crect width='45' height='45'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='undo' clip-path='url(%23clip-undo)'%3E%3Cg id='MDI_backspace-outline' data-name='MDI / backspace-outline' transform='translate(6.891 10.5)' opacity='0.6'%3E%3Crect id='Boundary' width='27.609' height='24' fill='%23fff' opacity='0'/%3E%3Cpath id='Path_backspace-outline' data-name='Path / backspace-outline' d='M21.857,15.59,20.235,17l-4.13-3.59L11.976,17l-1.622-1.41L14.483,12l-4.13-3.59L11.976,7l4.13,3.59L20.235,7l1.622,1.41L17.727,12l4.13,3.59M25.308,3a2.166,2.166,0,0,1,2.3,2V19a2.166,2.166,0,0,1-2.3,2H8.053a2.263,2.263,0,0,1-1.829-.89L0,12,6.224,3.88A2.256,2.256,0,0,1,8.053,3H25.308m0,2H8.053l-5.43,7,5.43,7H25.308Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    width: 100%;
    height: 100%;
    color: $colorOffWhite;
    text-align: center;
    font-family: emoji;
    font-size: 1.6em;
    line-height: .8;
    font-weight: bold;
    display: grid;
    place-content: center;
}
.submit::after{
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='45' height='45' viewBox='0 0 45 45'%3E%3Cdefs%3E%3CclipPath id='clip-submit'%3E%3Crect width='45' height='45'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='submit' clip-path='url(%23clip-submit)'%3E%3Cg id='MDI_progress-check' data-name='MDI / progress-check' transform='translate(4.069 4.069)' opacity='0.6'%3E%3Crect id='Boundary' width='36.861' height='36.861' fill='%23fff' opacity='0'/%3E%3Cpath id='Path_progress-check' data-name='Path / progress-check' d='M18.868,2.03v3.1a12.288,12.288,0,0,1,0,24.39v3.072a15.359,15.359,0,0,0,0-30.564M15.8,2.076A15.094,15.094,0,0,0,7.61,5.455l2.2,2.273a12.28,12.28,0,0,1,5.99-2.58V2.076M5.444,7.621A15.182,15.182,0,0,0,2.05,15.807H5.122a12.313,12.313,0,0,1,2.519-5.99l-2.2-2.2m17.263,4.347-7.5,7.5-3.256-3.256-1.628,1.628,4.884,4.884L24.336,13.6l-1.628-1.628M2.065,18.879A15.42,15.42,0,0,0,5.46,27.065l2.181-2.2a12.291,12.291,0,0,1-2.5-5.99H2.065m7.741,8.248-2.2,2.1A15.35,15.35,0,0,0,15.8,32.7V29.63A12.291,12.291,0,0,1,9.806,27.127Z' transform='translate(1.022 1.042)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    width: 100%;
    height: 100%;
    color: $colorOffWhite;
    text-align: center;
    font-family: emoji;
    font-size: 1.8em;
    line-height: .8;
    font-weight: bold;
    display: grid;
    place-content: center;
}

.virtualKeyboardSubmitAndClear{
    // grid-template-areas:
    // 'num1 num1 num3 num5 num5 submit'
    // 'num1 num1 num8 num5 num5 submit'
    // 'clear clear clear submit submit submit';
    grid-template-columns: repeat(6, 1fr);

        .submit{grid-area: 1/4/2/7;}

}

.correcOrWrong{
    @include borderRadius(5px);
    // border: solid 1px black;
    text-align: center;
    font-size: 55px;
    font-weight: bold;
    padding: 10px;
    margin: 5px;
    margin-top: 0px;
    background-color: #f9f9f9;

    .mark.correct{
        color: green;
    }

    .mark.wrong{
        color: red;
    }

    .inst{
        font-size: 12px;
        font-weight: normal;
        font-style: italic;
    }
}

/**/
.mainQuestion{
    font-size: 1.3em;
    font-weight: bolder;
    line-height: 1.3; 
    padding: 0.5ch 0.3ch 0.5ch 0.5ch;
    text-align: left;
    border-radius: 5px;
    color: #665;
    margin: 10px 0px;
    // background-image: url(../img/qblob.svg);
    // background-repeat: no-repeat;
    // background-size: 98%;
    // background-position: center;    
}
.mainQuestion::before{
    // content: 'i';
    content: '';
    font-size: 200%;
    line-height: 1;
    font-weight: bold;
    padding: 0 1ch 0 5px;
    margin-right: 4px;
    color: #248;
    opacity: .6;
    background-image: url(../img/teacher.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.mainQuestion.bottom{
    background-color: #e3edf4c6;
    background-image: unset;
    color: brown;
    text-align: left;
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1ch;
}

.mainQuestion.bottom::before{
    content: 'Q';
    background: none;
    font-size: 200%;
    line-height: 1;
    justify-self: flex-start;
    padding: 0 5px;
    color: #69c;
    opacity: .6;
}

.mainQuestionImage img{
    height: 12ch;
    display: grid;
    margin: auto;
    scale: 1.6;
    height: auto;
    scale: 1;
}

.mainQuestionText{
    padding: 1ch;
    max-width: 85%;
    line-height: 1.5;
    font-size: 1.3rem;
    color: #248;
    background-color: #eee6;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .word{
        @include borderRadius(3px);
        padding: 2px 10px;
        margin: auto 5px;
        background-color: #FEFEFE;
        outline: solid 1px #DDD;
        
    }
}

.inputFieldContainer{
    @include borderRadius(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: solid .5px green;
    margin: auto;
    height: 45px;
    width: 90%;
    line-height: 45px;
    font-size: 1.5em;
    padding: 0;
    text-align: center;
    background-color: #fff;
    // box-shadow: inset 0 0 24px .4px #0422;
}

.instructions {
    grid-area: 1/1/2/3;
}
.instructions span{
    font-weight: bold;
    color: #678d;
    margin-left: 5px;
    grid-area: 1/1/2/3;
}
.instructions::before{
    content: '';
    // font-family: Arial, Helvetica, sans-serif;
    font-size: 120%;
    display: inline-flex;
    width: 30px;
    height: 30px;
    // width: 1.4em;
    // transform: translateY(-2px);
    display: inline-flex;
    // filter: drop-shadow(1px 2px 1px #0009);
    background-image: url(../img/bulb.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 2.5px 5px;
    grid-area: 1/1/2/3;
}
.instructionsContent{
    // font-style: italic;
    color: #666;
    padding: 0 1ch;
    grid-area: 2/1/2/3;
}


.template2{
    .highlightOption{
        // margin-top: 10px;
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr;
        justify-content: space-around;
        gap: 10px;
        // margin: 10px;
        padding: 1ch;

        .options{
            @include borderRadius(2px);
            outline: solid 2px #00000011;
            text-align: center;
            // width: 160px;
            // height: 80px;

            img{
                // margin-top: -39%;
                // margin-left: -16%;
                // width: 90%;
                width: auto;
                max-width: 5em;
                scale: 1.6;
                mix-blend-mode: multiply;
                width: 95%;
                max-width: 250px;
                scale: 1;
            }
        }

        .options.active{
            outline: solid 3px #f009;
        }
    }
}
.options.active{
    outline: solid 3px #f009;
}

.template4{
    display: grid;
    grid-template-rows: auto 1fr;

    .mainQuestionImage{
        margin-top: 10px;
        canvas{
            @include borderRadius(5px);
            background-color: rgb(213, 234, 236);
            border: solid 1px rgb(157 175 176);
            // width: 100%;
            // height: 100%;
        }
    }
}

.templateMCQ{
    .optionsContainer{
        // margin-bottom: 40px;
        font-size: 1.1rem;

        ul{margin: 0px; padding: 0px; list-style-type: none;}

        ul li{
            @include borderRadius(4em);
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-items: center;
            margin: 5px auto;
            background-color: #9902;
            padding: 4px 2px;
            color: #248;
            font-weight: bolder;
            max-width: 80%;
            height: 3.5ch;

            .radio{width: 25px; height: 25px; cursor: pointer; margin-top: .2px;}

            span{display: inline-block; margin-left: 10px;}
        }
    }
}

.templateJumbled, .templateFIB{
    .optionsContainer, .answerContainer{
        margin: 5px auto;
        white-space: nowrap;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: 5px;
        min-height: 32px;
        width: 88%;
        padding: 1ch;
        background-color: #fadfc355;
        @include borderRadius(5px);

        .word{
            @include borderRadius(3px);
            outline: solid 1px #aaa;
            cursor: pointer;
            display: inline-block;
            // margin: auto 5px;
            padding: 5px 1ch;
            text-align: center;
            // min-width: 55px;
            // width: max-content;
            line-height: 1.2;
            color: #248;
            background-color: #fff9;
            display: grid;
            align-content: center;
        }

        .word.active{
            background-color: #c8e5c8;
        }
    }

    .answerContainer{
        background-color: #d3fac355;
    }
    .answerContainer::before{
        content: "▼ ▼ ▼";
        color: #ffa50099;
        width: auto;
        display: block;
        position: absolute;
        transform: translate(1ch, -2ch);
    }
}

.templateFIB{
    .mainQuestion{
        line-height: 1.4;
        padding: 0 2ch;
        // text-align: center;
    }

    .highlighted .fillInTheBlank{background-color: #f0fde9;}

    .fillInTheBlank{
        // @include borderRadius(3px);
        text-align: center;
        border-bottom: solid 1px black;
        // line-height: 35px;
        display: inline-block;
        min-width: 60px;
        // height: 25px;
        padding: 0px 20px;
    }
}

.templateMTF{
    .canvas{
        background-color: #d9efe5;
    }
}



.alertContainer{
    @include borderRadius(1em);
    z-index: 2;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0 1em 1em #0004);
    background-color: #fffa;
    overflow: hidden;
    animation: fade-in .15s ease-in;

    .backdrop{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        // backdrop-filter: blur(4px);
        background-repeat: no-repeat;
        background-position: center top;
    }
    .backdrop.correct{
        background-color: #eef8e8f1;
    }
    .backdrop.wrong{
        background-color: #f3ece4f0;
    }
    .backdrop.correct.congrats{
        background-color: #dcf1f5ec;
        background-position: center;
        animation: bonusColors 4s infinite;
    }
    @keyframes bonusColors {
        0%,100%{
            background-color: #dffc;
        }
        33%{
            background-color: #ffec;
        }
        66%{
            background-color: #dfdc;
        }
    }

    .alertContent{
        width: 100%;
        height: 100%;
        // position: absolute;
        color: $colorWhite;
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: larger;

        .contentContainer{
            @include borderRadius(5px);
            margin: auto;
            padding: 20px 0;
            text-align: center;
            display: grid;
            // flex-direction: column;
            justify-items: center;
            align-items: center;

            img{
                scale: .9;
            }
            .popupBtn{
                display: grid;
                width: inherit;
                margin-top: -4em;
            }
            .button{
                @include borderRadius(5em);
                line-height: 45px;
                width: auto;
                margin: 10px auto;
                padding: 0 15px;
                cursor: pointer;
                color: green;
                outline: solid .5px;
                transition: all .18s ease;
                scale: 1;
            }
            .button:hover, .button:active{
                background-color: #fffa;
                scale: 1.03;
            }
        }
        .contentContainer.correct,
        .contentContainer.wrong{
            width: 10em;
            height: 8em;
        }
        .contentContainer.undefined{
            width: 15em;
            height: 18em;
        }
        .wellDone{
            text-shadow: -5px -5px #f005, 8px 8px #0f05, -5px 3px #fff, -8px -8px #0f05;
            filter: drop-shadow(-4px 4px 4px #ff0);
            font-size: 1.7em;
            color: orangered;
            line-height: 1;
        }
        .qPlayed{
            font-size: 1em;
            color: #343833;
            line-height: 1;
        }
        .gifImage{
            // margin-top: 50%;
            width: 30%;
            max-width: 8em;
        }
        .message{
            color: rgb(184, 49, 0);
            display: inline-block;
            width: 80%;
            margin-top: 10px;
            font-style: italic;
            font-size: medium;
            font-weight: normal;
            b{
                font-size: 2em;
            }
            img{
                width: 40px;
                aspect-ratio: 1;
            }
        }
    }
}
.quesNum {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    background-color: #fff;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 20px 0 0 20px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 4px;
    grid-area: 1/3/3/4;
}

.audioControl {
    height: 50px;
    width: 50px;
    background-color: #efe;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 0 0 20px;
    cursor: pointer;
    filter: grayscale(1);
    background-image: url(../img/speaker.svg);
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    z-index: 3;
    transform: translate(5px, 50px) scale(0.9);
    grid-area: 1/3/3/4;
    justify-self: right;
  }
  .audioControl:active{
    transform: translate(5px, 75px) scale(0.84);
  }
  .audioControl.soundSwitch {
    filter: grayscale(0);
  }
  @media screen and (orientation: portrait) and (max-width: 640px){
    .audioControl{
      scale: .9;
    }
    .mainQuestionText{
        display: flex!important;
        padding: 0.5ch;
        row-gap: 0.7ch;
        margin: auto;
        max-width: 75%;
    }
    .template2 .highlightOption{
        display: flex;
        flex-direction: column;
        /* row-gap: 11ch; */
        width: 60%;
        margin: auto;
    }
  }
  @media screen and (orientation: portrait) and (max-width: 360px){
    .mainQuestion{
        margin: 2px;
    }
    .virtualKeyboard3{
        grid-template: repeat(1,1fr)/repeat(2,1fr);
        min-height: 4em;
    }
    .contentContainer.undefined{
        width: 90%!important;
        height: auto!important;
    }
    .templateJumbled, .templateFIB{
        .optionsContainer, .answerContainer{
        max-width: 80%;
        }
    }
  }
@media screen and (orientation: portrait) and (max-width: 960px){
    div#paheli_container {
        display: grid;
        grid-template-rows: 0 6ch auto 1fr;
        grid-template-columns: 1fr;
        row-gap: 5px;
        margin: 10px;
        // background-color: #f7f0a4a8;
    }
    .navigationPanel{
        grid-area: 2/1/3/2;
    }
    .questionArea{
        grid-area: 1/1/4/2;
        padding: 0;
    }
    .inputArea{
        grid-template: repeat(6, 1fr) / repeat(12, 1fr);
        grid-area: 4/1/6/1;
    }
    .inputFieldContainer{
        grid-area: 1/5/2/9;
        width: 100%;
    }
    .inputAreaKeys{
        grid-area: 1/1/7/13;
        display: grid;
        grid-template: repeat(6, 1fr) / repeat(12, 1fr);
        place-items: center;

        .clearAnswer{
            grid-area: 1/3/2/5;
        }

        .submit{
            grid-area: 1/9/2/11;
        }
    }
    .vkS{
        max-height: 20ch;
    }
    .vk1 .virtualKeyboard{
        margin: 0 5px;
    }
    .vk2 .clearAnswer,
    .vk3 .clearAnswer,
    .vkSC .clearAnswer{
        grid-column: 2/7;
        width: 75%;
    }
    .vk2 .submit,
    .vk3 .submit,
    .vkSC .submit{
        grid-column: 7/12;
        width: 75%;
    }
    .vkS .submit{
        grid-area: 1/1/4/9;
        width: 75%;
    }
    .virtualKeyboard2{
        display: none;
    }

    .virtualKeyboard, .virtualKeyboard2, .virtualKeyboard3{
        align-self: flex-start;
        grid-area: 2/1/7/9;
        height: fit-content;
        margin: 0 5px;
    }
    .virtualKeyboardSubmit, .virtualKeyboardSubmitAndClear{
        display: none;
    }
  // .vk3{
    //     .item1{grid-area: 1/1/5/4; color: green;}
    //     .item5{grid-area: 1/4/5/7; color: red;}
    // }
    .scoreBoard{
        grid-area: 2/9/6/13;
        height: fit-content!important;
        >div{
            height: 6ch;
        }
    }
    .vkS-input .scoreBoard{
        grid-area: 1/9/6/13;
    }
    .alertContainer{
        grid-area: 1/1/5/2;
    }    
    // .mainQuestion{
    //     padding: .5ch;
    // }
    .mainQuestionText{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(max-content, 5ch));
        justify-content: center;
    }
    .template3 .mainQuestionText{
        grid-template-columns: 80%;
        text-align: left;
        max-height: 50%;
        padding: 1ch;
    }
    // .template3 .mainQuestion{
    //     margin-top: 20%;
    // }
}

@media screen and (orientation: landscape), (min-width: 961px){
    div#paheli_container {
        @include borderRadius(5px);
        display: grid;
        grid-template: repeat(5, 1fr) / repeat(12, 1fr);
        // align-items: center;
        // background-color: #f7f0a4a8;
        // box-shadow: inset 0 0 0 1px #4444, 10px 10px #0002;
        // box-shadow: inset 0 0 0 .2px #444;
        gap: 5px;
        padding: 5px;
        width: 98%;
        max-width: 900px;
        min-height: 420px;
        margin: auto;
    }
    .navigationPanel{
        grid-area: 1/1/2/13;
    }
    .questionArea{
        grid-area: 1/1/5/8;
        padding: 1ch 2ch;
    }
    .inputArea{
        grid-template: repeat(5, 1fr) / repeat(12, 1fr);
        grid-area: 1/1/7/13;
    }
    .inputAreaKeys{
        grid-area: 1/1/7/13;
        display: grid;
        grid-template: repeat(5, 1fr) / repeat(12, 1fr);
        place-items: center;
    }

    .clearAnswer{
        grid-area: 5/2/7/2;
    }

    .submit{
        grid-area: 5/5/7/8;
    }
    .vk2 .clearAnswer,
    .vk3 .clearAnswer,
    .vkSC .clearAnswer{
        grid-column: 1/4;
        width: 75%;
    }
    .vk2 .submit,
    .vk3 .submit,
    .vkSC .submit{
        grid-column: 5/8;
        width: 75%;
    }
    .vkS .submit{
        grid-column: 2/7;
        width: 75%;
    }

    .inputFieldContainer{
    grid-area: 5/3/7/6;
    }
    .virtualKeyboard, .virtualKeyboard2, .virtualKeyboard3, .virtualKeyboardSubmit, .virtualKeyboardSubmitAndClear{
        grid-area: 2/8/5/11;
        margin: 0 10px;
    }
    .scoreBoard{
        grid-area: 2/11/5/13;
        height: 70%;
    }
    .alertContainer{
        grid-area: 1/1/6/8;
    }
}
.mobile-device .vkS .virtualKeyboardSubmit{
    display: none;
}