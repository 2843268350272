@import './../index.scss';

.card{
    @include borderRadius(5px);
    background-color: $colorWhite;
    box-shadow: 0px 0px 4px -2px black;
    padding: 10px;
}

.page{
    // background-color: $colorPaleSky;
    height: auto;
    width: 100%;
    // max-width: 650px;
    // max-height: 800px;
    display: grid;
    grid-template-rows: 1fr;
    margin: auto;
    // background: #ccc6;
}


.page.introPage{
    min-width: 300px;
    min-height: 650px;
    grid-template-rows: 60px auto 30px;

    .pageContent{
        display: grid;
        grid-template-rows: 1fr auto;
        align-content: space-between;
        padding: 10px;
        text-align: center;
    }

    .pageTitle{
        @include borderRadius(10px);
        font-size: 150%;
        font-weight: bold;
        color: darkslateblue;
        background-color: #fffa;
        width: 80%;
        margin: auto;
        padding: 1ch 0;
    }

    .startButton{
        color: rgb(255 215 0);
        background-color: #4b8ad3;
        border-radius: 100px;
        height: 150px;
        width: 150px;
        padding: 20px 20px;
        text-align: center;
        font-size: 1.5rem;
        // line-height: 130px;
        margin: auto;
        font-weight: bold;
        display: grid;
        align-content: center;
        // filter: drop-shadow(0 0 20px);
        box-shadow: 0 20px 20px #0003, 0 0 40px 1px;
    }

    .startButton:hover{
        background-color: #7d92aa;
        cursor: pointer;
        transform: scale(1.05);
    }
    .startButton:active{
        background-color: #7daa92;
        cursor: pointer;
        transform: scale(.96);
    }
}

.page.gamePage{
    grid-template-rows: 1fr;
    height: 100%;
    font-family: Cambay, sans-serif;
    .navigationPanel{
        @include borderRadius(5px);
        display: grid;
        grid-template-columns: auto auto 1fr auto auto;
        align-items: center;
        justify-items: center;
        background-color: #ddebfc;
        margin: 0 5px;
        opacity: 0.6;
        z-index: 06;

        .button{
            @include borderRadius(6px);
            height: 40px;
            filter: drop-shadow(-1px 2px #0003)
        }
        .button:active{
            filter: drop-shadow(-1px 2px #0000)
        }
    }

    .questionNumber{
        @include borderRadius(3px);
        background-color: #fbdf49;
        outline: .5px solid;
        padding: 4px 1ch;
        width: fit-content;
        margin: 8px;
        min-width: 8ch;
        text-align: center;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 110%;
        white-space: nowrap
    }

    .questionArea{
        @include borderRadius(5px);
        z-index: 1;
        margin: 5px;
        // background-color: $colorWhite;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
        font-weight: bold;
        text-align: center;
    }
    .questionArea > div:nth-of-type(1) {
        display: flex;
        flex-direction: row;
        // height: 100%;
        justify-content: flex-start;
        gap: 5px;
        // max-width: 90%;
    }
    // .templateMCQ.questionArea,
    .templateJumbled.questionArea,
    .templateFIB.questionArea{
         > div:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            // height: 100%;
            justify-content: space-evenly;
            gap: 5px;
        }
    }
    .inputArea{
        display: grid;
    }
    .scoreBoard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        text-align: center;
        padding: 20px 10px;
        line-height: 1;
        font-variant: small-caps;
        color: #333;
        height: auto;
        max-width: 14ch;

        .totalScore, .currentScore{
            @include borderRadius(10px 10px 0 0);
            display: grid;
            align-content: stretch;
            grid-template-rows: 2.8fr 1fr;
            height: 5ch;
        }
        .totalScore{
            background-color: #DBF1EF;
        }
        .currentScore{
            background-color: #FFE2CC;
        }
        .scoreDigit{
            width: 100%;
            font-size: 1.6em;
            display: grid;
            place-content: center;
            font-family: 'Cambay' serif;
            font-weight: bold;
            padding: 5px 0;
        }
        .scoreTitle{
            width: 100%;
            font-size: .9em;
            font-weight: bold;
            background-color: #fff;
            display: grid;
            place-content: center;
            padding: 2px 0;
        }
        .border{
            border-color: #ccc0;
        }
    }

    .instructionArea{
        @include borderRadius(5px);
        // background-color: #d8aaaa11;
        padding: .5ch;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        grid-template-rows: 1fr auto;
        justify-content: space-between;
        width: 100%;
        // grid-template-columns: minmax(100%, 450px);
        text-align: left;
        font-size: 1.2em;
        font-weight: normal;
        order: -1;
        // display: none;
    }

    .submitButton{
        background-color: $colorGreen;
        margin: 5px;
        margin-top: 0px;
    }

    .submitButton.disabled{
        background-color: #ccc;
        color: #999;
    }
}

.page.introPage{
    .header{
        grid-area: 1/1/2/1;
    }
    .pageContent{
        grid-area: 2/1/3/1;
    }
    .footer{
        grid-area: 3/1/4/1;
    }
}
.bg-salmon {
    background-color: salmon;
    color: white;
  }

@keyframes fade-in {
from {
    opacity: 0;
    scale: .6;
    transform-origin: 50% 150%;
}
to {
    opacity: 1;
    scale: 1;
}
}