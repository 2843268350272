html,body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  // background-color: #df7;
}
#root{
  width: 100%; 
  height: 100%;
  // background-image: url(../public/assets/images/logoLandingPage.svg);
  background-size: 350%;
  background-repeat: no-repeat;
  background-position: center;
}


a{
  text-decoration: none !important;
  width: fit-content;
  margin: auto;
  cursor: pointer;
}

link, button, .button{
  cursor: pointer;
}

/**/
$colorYellow: #ffd954;
$colorBlue: #4b8ad3;
$colorGreen: #9CBD1C;
$colorRed: #DE6D3E;
$colorOffWhite: #EFEFEF;
$colorPaleSky: #e2edf0;
$colorWhite: #FFFE;

@mixin borderRadius($radius) {
  border-radius: $radius;
  // overflow: hidden;
}

/**/
.bgRed{background-color: $colorRed;}
.bgOffWhite{background-color: $colorOffWhite;}
.border{outline: solid 1px #999;}
.tc{text-align: center;}

.grid{
  display: grid;
}
.flex{
  display: flex;
}
.flexCenter{
  display: flex;
  align-items: center;
}

// .gc1{grid-template-columns: 1fr;}
.gc1{grid-template-columns: 1fr;}
.gc2{grid-template-columns: 1fr 1fr;}
.gc3{grid-template-columns: 1fr 1fr 1fr;}
.gc4{grid-template-columns: repeat(4, 1fr);}
.gc5{grid-template-columns: repeat(5, 1fr);}
.gc6{grid-template-columns: repeat(6, 1fr);}
.gc7{grid-template-columns: repeat(7, 1fr);}
.gc8{grid-template-columns: repeat(8, 1fr);}
.gc9{grid-template-columns: repeat(9, 1fr);}
.gc10{grid-template-columns: repeat(10, 1fr);}
.gc11{grid-template-columns: repeat(11, 1fr);}
.gc12{grid-template-columns: repeat(12, 1fr);}

.gap1{gap: 1px;}
.gap2{gap: 2px;}
.gap3{gap: 3px;}
.gap4{gap: 4px;}
.gap5{gap: 5px;}
.gap6{gap: 6px;}
.gap7{gap: 7px;}
.gap8{gap: 8px;}
.gap9{gap: 9px;}
.gap10{gap: 10px;}
.gap11{gap: 11px;}
.gap12{gap: 12px;}

.w100{width: 100%;}
.h100{height: 100%;}
.wh100{width: 100%; height: 100%;}

.taCenter{text-align: center;}
.taStart{text-align: start;}
.taEnd{text-align: end;}

.acCenter{align-content: center;}
.acStart{align-content: flex-start;}
.acEnd{align-content: flex-end;}

.jcStart{justify-content: start;}
.jcEnd{justify-content: end;}
.jcCenter{justify-content: center;}
.jcSpaceAround{justify-content: space-around;}
.jcSpaceBetween{justify-content: space-between;}
.jcSpaceEvenly{justify-content: space-evenly;}

.padding0{padding: 0px;}
.padding1{padding: 1px;}
.padding2{padding: 2px;}
.padding3{padding: 3px;}
.padding4{padding: 4px;}
.padding5{padding: 5px;}
.padding6{padding: 6px;}
.padding7{padding: 7px;}
.padding8{padding: 8px;}
.padding9{padding: 9px;}
.padding10{padding: 10px;}
.padding11{padding: 11px;}
.padding12{padding: 12px;}

.margin0{margin: 0px;}
.margin1{margin: 1px;}
.margin2{margin: 2px;}
.margin3{margin: 3px;}
.margin4{margin: 4px;}
.margin5{margin: 5px;}
.margin6{margin: 6px;}
.margin7{margin: 7px;}
.margin8{margin: 8px;}
.margin9{margin: 9px;}
.margin10{margin: 10px;}
.margin11{margin: 11px;}
.margin12{margin: 12px;}

.overflowAuto{overflow: auto;}